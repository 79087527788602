import { Component, OnInit } from '@angular/core';
import { RequestService } from '../request.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-etsy-marketplace',
  templateUrl: './etsy-marketplace.component.html',
  styleUrls: ['./etsy-marketplace.component.css']
})
export class EtsyMarketplaceComponent implements OnInit {
  name: string;
  tracker: any;

  constructor(private requestService: RequestService) {
  }

  sortArray = (a, b) => {
    if (a < b) return 1;
    if (b < a) return -1;
    return 0;
  };

  ngOnInit(): void {
    const res = this.requestService.getUnshippedEtsyOrders()
    let data: any
    res.subscribe((data) => {
      console.log(data)
      data = data;

      let tracker = {};
      tracker["ordersAwaitingShipping"] = data.count

      _.forEach(data.results, (result: any) => {
        _.forEach(result.Transactions, (transaction: any) => {

          //Listing already in object
          if (tracker.hasOwnProperty(transaction.listing_id)) {
            if (transaction.variations.length > 0) {
              let setArray = []
              _.forEach(transaction.variations, (variation: any) => {
                setArray.push(variation.value_id)
              })

              setArray.sort(this.sortArray);

              if (tracker[transaction.listing_id].sets.hasOwnProperty(setArray.toString())) {
                tracker[transaction.listing_id].sets[setArray.toString()].count+= transaction.quantity
                if (result.is_overdue) {
                  tracker[transaction.listing_id].sets[setArray.toString()].lateCount += transaction.quantity
                }
              } else {

                tracker[transaction.listing_id].sets[setArray.toString()] = {
                  attributes: {},
                  count: 1,
                  lateCount: result.is_overdue ? transaction.quantity : 0
                }
                _.forEach(transaction.variations, (variation: any) => {
                  tracker[transaction.listing_id].sets[setArray.toString()].attributes[variation.formatted_name] = variation.formatted_value
                })
              }
            } else {
              tracker[transaction.listing_id].count += transaction.quantity
            }
            // Listing missing
          } else {
            if (transaction.variations.length > 0) {
              tracker[transaction.listing_id] = {
                hasSets: true,
                sets: {},
                title: transaction.title,
                image: transaction.MainImage.url_170x135
              }

              let setArray = []
              _.forEach(transaction.variations, (variation: any) => {
                setArray.push(variation.value_id)
              })

              setArray.sort(this.sortArray);

              tracker[transaction.listing_id].sets[setArray.toString()] = {
                attributes: {},
                count: transaction.quantity,
                lateCount: result.is_overdue ? transaction.quantity : 0
              }

              _.forEach(transaction.variations, (variation: any) => {
                tracker[transaction.listing_id].sets[setArray.toString()].attributes[variation.formatted_name] = variation.formatted_value
              })
            }
            else {
              tracker[transaction.listing_id] = {
                hasSets: false,
                count: transaction.quantity,
                image: transaction.MainImage.url_170x135,
                title: transaction.title,
                lateCount: result.is_overdue ? transaction.quantity : 0
              }
            }
          }
        })
      })
      this.tracker = tracker
    })
  }
}
