import { Component, OnInit } from '@angular/core';
import { RequestService } from '../request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userName: string
  password: string

  constructor(private requestServive: RequestService, private router: Router) {
  }

  login() {
    this.requestServive.login(this.userName, this.password).subscribe(
      data => {
        localStorage.setItem("userInfo", JSON.stringify({userName: this.userName, password: this.password}))
        this.router.navigate([""])
      },
      err => {
        this.userName = ""
        this.password = ""
        console.log(err)
      })
  }

  ngOnInit(): void {
  }

}
