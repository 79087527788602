import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      let userInfo = localStorage.getItem("userInfo")
      if(userInfo){
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }
}
