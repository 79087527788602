<div class="ui form">
    <div class="field">
        <label>User Name</label>
        <input  [(ngModel)]="userName" type="text" placeholder="User Name">
    </div>
    <div class="field">
        <label>Password</label>
        <input [(ngModel)]="password" type="text" placeholder="Password">
    </div>
    <button class="ui button" (click)="login()">login</button>
</div>