import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string): any{
    let authString = btoa(`${username}:${password}`)
    console.log(authString)
    const headerDict = {
      'Authorization': `BASIC ${authString}`,
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict),
      responseType: "text" as "text"
    };

    return this.http.get( "https://dev-api.jd.fleasknees.com/login", requestOptions)

  }

  getUnshippedEtsyOrders(): any {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(userInfo)
    let authString = btoa(`${userInfo.userName}:${userInfo.password}`)
    console.log(authString)
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `BASIC ${authString}`,
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this.http.get( "https://dev-api.jd.fleasknees.com/etsy", requestOptions)
  }
}
