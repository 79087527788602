<p>Inceptualize Marketplaces!</p>

<div class="ui grid" style="margin-right: 25px; margin-left: 25px;">
    <div class="two wide column" *ngFor="let item of tracker | keyvalue | filterNonObjects">
        <img class="ui centered rounded image" src={{item.value.image}}>
        <div class="ui raised segments" *ngIf="!item.value.hasSets">
            <div>
                <p class="ui centered">count: {{item.value.count}} <span style="color: red;" *ngIf="item.value.lateCount > 0">({{item.value.lateCount}} late)</span></p>
            </div>
        </div>
        <div class="ui raised segments" *ngIf="item.value.hasSets">
            <div class="ui segment" style="margin-bottom: 5px;" *ngFor="let set of item.value.sets | keyvalue">
                <p style="margin-bottom:0" *ngFor="let attribute of set.value.attributes | keyvalue">{{attribute.key}}: {{attribute.value}}</p>
                <p>Count: {{set.value.count}} <span style="color: red;" *ngIf="set.value.lateCount > 0">({{set.value.lateCount}} late)</span></p>
            </div>
        </div>
    </div>
</div>