import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
    name: 'filterNonObjects',
    pure: false
})
export class FilterNonObjectPipe implements PipeTransform {
    transform(items: any[]): any {
        console.log(items)
        return items.filter((item) => {
            return _.isObject(item.value)
        })
    }
}