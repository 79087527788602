import { Injectable } from '@angular/core';
import { AuthService } from './auth.service'
import { promise } from 'protractor';
import { CanActivate, Router } from '@angular/router';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    return new Promise(resolve => {
      this.auth.isLoggedIn().then((status) => {
        if(status === false){
          this.router.navigate(["login"])
        }
        resolve(status)
      })
    })
  }
}
