import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EtsyMarketplaceComponent } from './etsy-marketplace/etsy-marketplace.component';
import { AuthGaurdService } from './auth/auth-gaurd.service'
import { LoginComponent } from './login/login.component';


const routes: Routes = [{path: 'login', component: LoginComponent}, { path: '', component: EtsyMarketplaceComponent, canActivate: [AuthGaurdService] }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
